import React from "react"
import styled from "styled-components"

const StyledArtistContent = styled.div`
  position: relative;
  padding: 2em 0;
  @media ( min-width: 48em ) {
    padding: 4em 0;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -50vw;
    width: 100vw;
    background-color: var(--color-white);
  }
  @media ( min-width: 60em ) {
    &::before {
     margin-left: 0;
     right: 0;
     left: auto;
    }
  }
  details {
    position: relative;
    z-index: 1;
    max-width: 38.2em;
    margin: 0 auto;
    &:not(:last-of-type) {
      margin-bottom: 2em;
    }
  }
  @media ( min-width: 60em ) {
    details {
      margin-left: 0;
      margin-right: 3em;
    }
  }
  
  summary {
    position: relative;
    z-index: 1;
    outline: none;
    cursor: default;
    list-style:none;
  }
  summary::-webkit-details-marker {
    display: none;
  }
  .can-hover & summary:hover::before,
  summary:active::before {
    opacity: 1;
  }
  summary::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -0.375rem;
    right: 0;
    height: 0.75rem;
    width: 0.75rem;
    background-image: linear-gradient(to right, transparent, transparent calc(50% - 1px), currentColor calc(50% - 1px), currentColor calc(50% + 1px), transparent calc(50% + 1px), transparent 100%), linear-gradient(to bottom, transparent, transparent calc(50% - 1px), currentColor calc(50% - 1px), currentColor calc(50% + 1px), transparent calc(50% + 1px), transparent 100%);
  }
  details[open] summary::after {
    transform: rotate(45deg);
  }

  summary {
    font-weight: 500;
    font-size: 1.125em;
    padding: 0;
    margin-bottom 1em;
  }
  details > blockquote,
  details > div {
    font-size: 0.875em;
  }
  blockquote {
    position: relative;
    margin: 0 0 1.5em;
    padding-left: 1.5em;
  }
  blockquote::before {
    content: '';
    position: absolute;
    top: 0.3em;
    bottom: 0.375em;
    left: 0;
    width: 1px;
    background-color: var(--color-black);
  }
  blockquote cite {
    font-style: normal;
    &::before {
      content: '\\2014';
    }
  }
  p {
    margin: 1em 0;
  }
  p:first-of-type {
    margin-top: 0;
  }
`

const StyledArtistContentVideo = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    margin-bottom: 1.5em;
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-black);
    }
`

const ArtistContent = props => {
  
  const artist = props.artistData

  return (
    <StyledArtistContent>
      {artist.behind_the_music &&
        <details open>
          <summary>Behind the Music</summary>
          {artist.interview_video &&
            <StyledArtistContentVideo>
            
              <iframe title="interview-video" id="video-interview" width="560" height="315" src={`https://www.youtube.com/embed/${artist.interview_video}?autoplay=0&color=white&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&fs=1`} frameborder="0" allow="encrypted-media" allowfullscreen></iframe>
            </StyledArtistContentVideo>
          }
          <blockquote>
            <div dangerouslySetInnerHTML={{ __html: artist.behind_the_music }} />
            <cite>{artist.name}</cite>
          </blockquote>
        </details>
      }
    {artist.bio &&
      <details open={ ! artist.behind_the_music ? true : null}>
        <summary>About {artist.name}</summary>
        <div dangerouslySetInnerHTML={{ __html: artist.bio }} />
      </details>
    }
    </StyledArtistContent>
  )
}

export { StyledArtistContent }

export default ArtistContent