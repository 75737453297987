import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const StyledSingleAlbumArtwork = styled.figure`
    position: relative;
    z-index: 2;
    line-height: 0;
    background-color: var(--color-offwhite);
    //box-shadow: 0 0.25em 1.5em -0.75em rgba(0,0,0,0.6);
    //border-radius: calc(2 * var(--border-radius));
    box-shadow: 0 0.75em 2.5em -1em rgba(0,0,0,0.6);
    &.is-empty::before {
      content: '';
      position: relative;
      display: block;
      padding-bottom: 100%;
      background-color: var(--color-gray);
      border-radius: inherit;
    }
    img {
      //border-radius: calc(2 * var(--border-radius));
    }
    @media ( max-width: 48em ) {
      width: 100%;
      max-width: 16em;
      margin-right: auto;
      margin-left: auto;
    }
    /*
    &::before {
      content: '';
      position: absolute;
      right: 1em;
      left: 1em;
      top: 100%;
      height: 1.75em;
      background-color: rgba(0,0,0,0.1);
    }
    */
    
`

const SingleAlbumArtwork = props => {

  const album = props.albumData

  const albumCover = album.cover && album.cover.localFile ? album.cover.localFile.childImageSharp.fluid : false

  return (

    <StyledSingleAlbumArtwork className={ ! albumCover ? `is-empty` : null}>
      {albumCover &&
        <Img fluid={albumCover} alt={`${album.title}`} />
      }
    </StyledSingleAlbumArtwork>
  )
}

export { StyledSingleAlbumArtwork }

export default SingleAlbumArtwork