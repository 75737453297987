import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import LayoutDirectory from "components/layoutDirectory"
import MiniAlbum from "components/album/miniAlbum"
import { StyledAlbumGrid } from "components/styledAlbumGrid"
import Media from "react-media"
import ArtistContent, { StyledArtistContent } from "components/artist/artistContent"
import SingleAlbum from "components/album/singleAlbum"
import { noise } from "components/css/noise"
import AwardAnnouncement from "../components/svg/AwardAnnouncement"

const StyledArtist = styled.article`
  max-width: 60em;
  margin-right: auto;
  margin-left: auto;
  @media ( min-width: 60em ) {
    position: relative;
    display: grid;
    grid-template: auto / 80% 10% 10%;
    ${StyledArtistContent} {
      grid-column: 1;
      grid-row: 3;
    }
    .show-player & {
      margin-left: 0;
    }
  }
`
const StyledArtistAlbums = styled.div`
  @media ( min-width: 60em ) {
    grid-column: 1 / span 2;
    grid-row: 2;
  }
  
`

const StyledArtistHeader = styled.header`
  position: relative;
  z-index: 1;
  margin-bottom: 1.5em;
  .pageTitle {
    margin-bottom: 0.25em;
    font-weight: 700;
  }
  p {
    font-size: 0.875em;
    font-weight: 500;
    margin: 0.25em 0 0;
  }
  @media ( max-width: 48em ) {
    text-align: center;
    width: 100%;
  }
  @media ( min-width: 48em ) {
    margin-bottom: 3em;
  }
  @media ( min-width: 60em ) {
    grid-column: 1;
    border-left: 0.5em solid;
    padding-left: 1em;
    place-self: start;
  }
`

const StyledArtistHeaderBackground = styled.span`
  display: none;
  @media ( min-width: 60em ) {

    position: sticky;
    display: block;
    top: 0;
    grid-column: 2 / span 2;
    grid-row: 1 / span 3;
    overflow: hidden;
    margin-top: calc( 0em - 3.8125em - 3.3125em - 2.5em);
    height: 100vh;
    width: calc(100% + ((100vw - 60em) / 2));
    .show-player & {
      width: calc(100vw - (200px + 3em) - ((100% * 5) - 100% + 3em) - 1px);
    }
    .gatsby-image-wrapper {
      position: absolute;
      right: 0;
      height: 150%;
      top: 50%;
      transform: translate3d(0,-50%,0);
      img {
        object-position: center right !important;
      }
    }
    .show-player & {
      margin-right: -3em;
    }
    ${noise}
  }
`

const StyledArtistSimilar = styled.aside`
  position: relative;
  padding: 3em 0 0;
  .pageTitle {
    text-align: center;
  }
`

export default ({ data }) => {
  const artist = data.artist
  const albums = artist.albums.reverse()
  const albumCover = albums[0] && albums[0].cover && albums[0].cover.localFile ? albums[0].cover.localFile.childImageSharp.fluid : false
  const albumCoverLarge = albums[0] && albums[0].cover && albums[0].cover.localFile ? albums[0].cover.localFile.childImageSharp.large : false
  return (
    <>
    <LayoutDirectory>
      <Helmet>
        <title>{artist.name} | Artists | {data.site.siteMetadata.title}</title>
      </Helmet>
      <StyledArtist>
        <Media query="(min-width: 60em)" render={() => (
            <>
              {albumCoverLarge &&
                <StyledArtistHeaderBackground>
                  <Img fluid={albumCoverLarge} />
                </StyledArtistHeaderBackground>
              }
            </>
          )}
        />
        <StyledArtistHeader>
            <h1 className="pageTitle">{artist.name}</h1>
            <p>{artist.location && artist.location}</p>
        </StyledArtistHeader>
        <StyledArtistAlbums>
        {albums.map(( album ) =>
          <SingleAlbum albumData={album} />
        )}
        </StyledArtistAlbums>
        <ArtistContent artistData={artist} />
      </StyledArtist>
      {data.genre && data.genre.name &&
        <StyledArtistSimilar>
          <h3 className="pageTitle">Explore <strong>{data.genre.name}</strong></h3>
          <StyledAlbumGrid>
            {data.albums.edges.map(({ node }) =>
              <MiniAlbum key={node.id} albumData={node} />
            )}
          </StyledAlbumGrid>
        </StyledArtistSimilar>
      }
    </LayoutDirectory>
    <AwardAnnouncement />
    </>
  )
}

export const query = graphql`
    query($id: String!, $genreDirectusId: Int!, $directusId: Int!) {
        artist: directusArtist(id: { eq: $id }) {
            name
            slug
            location
            behind_the_music
            bio
            interview_video
            albums {
              id
              artist {
                name
                slug
              }
              genre {
                name
                slug
              }
              cover {
                localFile {
                    childImageSharp {
                        fluid(maxWidth:900) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                        large: fluid(maxWidth:1280) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
              }
              link_amazon
              link_apple
              link_spotify
              title
              slug
              type
              tracks {
                title
                video_id
                featured
                byline
              }
            }
        }
        albums: allDirectusAlbum(filter: {wave: {in: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35"]}, artist: {directusId: {lt: $directusId}}, genre: {elemMatch: {directusId: {eq: $genreDirectusId}}}}, sort: {fields: title, order: ASC}, limit: 5) {
          edges {
            node {
              ...AlbumExcerpt
            }
          }
        }
        genre: directusGenre(directusId: {eq: $genreDirectusId}) {
            name
            slug
        }
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`
