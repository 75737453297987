import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import SingleAlbumArtwork, { StyledSingleAlbumArtwork } from "components/album/singleAlbumArtwork"
import AlbumStreamOptions from "components/album/albumStreamOptions"
import TrackList from "components/album/tracks/list"

const StyledSingleAlbum = styled.section`
  position: relative;
  z-index: 2;
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: 1.5em;
  padding-bottom: 3em;
  @media ( max-width: 48em ) {
    &:not(:last-of-type) {
      border-bottom: 1px solid var(--color-divider);
    }
  }
  @media ( min-width: 48em ) {
    grid-template: auto / 38% 1fr;
    grid-template: 1fr / 1fr 1fr;
    grid-gap: 2.5em 4em;
    align-items: start;
    ${StyledSingleAlbumArtwork} {
      position: sticky;
      top: calc( 3.3125em + 4.5em );
      grid-column: 2;
      grid-row: 1;
    }
    &:not(:first-of-type) {
      margin-top: 6em;
    }
    margin-right: auto;
    margin-left: auto;
    padding: 2.5em;
    padding: 0;
    margin-bottom: 3em;
    border-radius: calc(4* var(--border-radius));
    border: 0;

  }
  @media ( min-width: 60em ) {
    padding-left: 0;
    margin-left: 0;
  }
`

const StyledAlbumContent = styled.div`
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: 1.5em;
  place-items: start;
  header {
    margin-bottom: 1em;
    @media ( max-width: 48em ) {
      text-align: center;
      width: 100%;
    }
    h2 {
      font-weight: 700;
      font-size: 1.25em;
      line-height: 1;
      margin: 0;
    }
    @media ( min-width: 48em ) {
      h2 {
        font-size: 2em;
      }
    }
    @media ( min-width: 60em ) {
      h2 {
        font-size: 2.125em;
      }
    }
    p {
      font-size: 0.875em;
      line-height: 1;
      margin: 0.625em 0 0;
      span:not(:last-of-type)::after {
        content: ", ";
      }
      a {
        font-weight: 500;
        letter-spacing: 0.025em;
      }
      .can-hover & a:hover {
        text-decoration: underline;
      }
    }
  }
`

const SingleAlbum = props => {
  
  const album = props.albumData

  return (
    <StyledSingleAlbum>
      <SingleAlbumArtwork albumData={album} />
      <StyledAlbumContent>
        <header>
          <h2>{album.title}</h2>
          <p>{album.genre.map((genre) =>
            <span>
              <Link to={`/genres/${genre.slug}`} title={`All ${genre.name.toLowerCase()} albums`}>{genre.name}</Link>
            </span>
          )}</p>
        </header>
        <AlbumStreamOptions albumData={album} />
        <TrackList albumData={album} />
      </StyledAlbumContent>
    </StyledSingleAlbum>
  )
}

export { StyledSingleAlbum }

export default SingleAlbum