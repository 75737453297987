import React from "react"
import styled from "styled-components"

import { Button } from "components/button"
import IconPlay from "components/svg/iconPlay"
import IconPause from "components/svg/iconPause"

import usePlayer from "hooks/usePlayer"
import AlbumLinks from "components/album/links"

const StyledAlbumStreamOptions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  ${Button} {
    margin-right: 1.875em;
  }
  @media ( max-width: 48em ) {
    justify-content: space-between;
    padding: 0.625em 1em 0.625em 0.75em;
    background-color: var(--color-white);
  }
`

const AlbumStreamOptions = props => {

  const album = props.albumData

  const { 
    loadAndPlayPlaylist,
    currentAlbum, 
    isPlaying,
    showPlayer
  } = usePlayer()

  return (
    <StyledAlbumStreamOptions>
      {album.tracks && album.tracks.length > 0 &&
        <Button onClick={() => { loadAndPlayPlaylist( album, 0 ); showPlayer() }}>
          <i>
            {isPlaying && typeof currentAlbum !== "undefined" && currentAlbum.slug === album.slug ? <IconPause /> : <IconPlay />}
          </i>
          Listen
        </Button>
      }
      <AlbumLinks albumData={props.albumData} />
    </StyledAlbumStreamOptions>
  )
}

export default AlbumStreamOptions
